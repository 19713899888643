export const store = {
  install: (Vue) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const role = localStorage.getItem("role") || "eqpdtiuo";
    let dark = localStorage.getItem("dark") || "false";
    dark = dark == "false" ? false : true;
    Vue.prototype.$state = Vue.observable({ user, role, dark });

    Vue.mixin({
      watch: {
        "$state.role": {
          immediate: true,
          handler(val) {
            if (val && localStorage.getItem("role") != val) {
              localStorage.setItem("role", val);
            }
          },
        },
        "$state.dark": {
          immediate: true,
          handler(val) {
            localStorage.setItem("dark", val);
          },
        },
        "$state.user": {
          immediate: true,
          deep: true,
          handler(val) {
            if (
              val &&
              JSON.parse(localStorage.getItem("user") || "{}") != val
            ) {
              localStorage.setItem("user", JSON.stringify(val));
            }
          },
        },
      },
    });
  },
};
