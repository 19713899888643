<template>
  <v-app>
    <v-theme-provider>
      <Home />
      <router-view />
    </v-theme-provider>
  </v-app>
</template>

<script>
import Home from "@/views/Home";
import { emitter } from "@/events";
import Noty from "noty";
export default {
  name: "App",
  components: {
    Home,
  },

  mounted() {
    const options = {
      theme: "sunset",
      timeout: 5000,
    };
    emitter.on("alert:error", (message) => {
      new Noty({
        ...options,
        text: message,
        type: "error",
      }).show();
    });
    emitter.on("alert:info", (message) => {
      new Noty({
        ...options,
        text: message,
        type: "info",
      }).show();
    });
    emitter.on("alert:success", (message) => {
      new Noty({
        ...options,
        text: message,
        type: "success",
      }).show();
    });
    emitter.on("alert:warning", (message) => {
      new Noty({
        ...options,
        text: message,
        type: "warning",
      }).show();
    });
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1118 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
</style>