import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3FA14C",
        secondary: "#CF3034",
        accent: "#cddc39",
        error: "#f44336",
        warning: "#ff5722",
        info: "#2196f3",
        success: "#009688",
      },
      dark: {
        primary: "#a2a2a2",
        secondary: "#CF3034",
        accent: "#cddc39",
        error: "#f44336",
        warning: "#ff5722",
        info: "#2196f3",
        success: "#009688",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
