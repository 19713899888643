import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/perfil/:uid",
    name: "app:perfil_servidor",
    component: () => import("../views/PerfilServidor.vue"),
    props: true,
  },
  {
    path: "",
    name: "app",
    component: () => import("../views/index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "app:home",
        component: () => import("../views/About.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "app:logout",
    component: () => import("../views/Logout.vue"),
  },
  {
    path: "/login",
    name: "app:login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/admin",
    name: "app:admin:index",
    component: () => import("../views/index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "pdti",
        name: "app:admin:pdti",
        component: () => import("../views/admin/PDTIC.vue"),
      },
      {
        path: "usuarios",
        name: "app:admin:usuario",
        component: () => import("../views/admin/Usuario.vue"),
      },
      {
        path: "naturezas-despesa",
        name: "app:admin:natureza_despesa",
        component: () => import("../views/admin/NaturezaDespesa.vue"),
      },
      {
        path: "habilidades",
        name: "app:admin:habilidade",
        component: () => import("../views/admin/Habilidade.vue"),
      },
      {
        path: "categorias",
        name: "app:admin:categoria",
        component: () => import("../views/admin/Categoria.vue"),
      },

      {
        path: "unidades-organizacionais",
        name: "app:admin:unidade_organizacional",
        component: () => import("../views/admin/UnidadeOrganizacional.vue"),
      },
      {
        path: "itens",
        name: "app:admin:item",
        component: () => import("../views/admin/Item.vue"),
      },
      {
        path: "templates",
        name: "app:admin:template",
        component: () => import("../views/admin/Template.vue"),
      },
    ],
  },
  {
    path: "/eqpdti",
    name: "app:eqpdti:index",
    component: () => import("../views/index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "necessidades",
        name: "app:eqpdti:necessidade",
        component: () => import("../views/eqpdti/Necessidade.vue"),
      },
      {
        path: "solicitacao-itens",
        name: "app:eqpdti:solicitacao_item",
        component: () => import("../views/eqpdti/Item.vue"),
      },
      {
        path: "contratos",
        name: "app:eqpdti:contrato",
        component: () => import("../views/eqpdti/Contrato.vue"),
      },
      {
        path: "inventario",
        name: "app:eqpdti:inventario",
        component: () => import("../views/eqpdti/Inventario.vue"),
      },
      {
        path: "recursos-humanos",
        name: "app:eqpdti:recursos_humanos",
        component: () => import("../views/eqpdti/RecursoHumano.vue"),
      },
      {
        path: "pdtic-anterior",
        name: "app:eqpdti:pdtic_anterior",
        component: () => import("../views/eqpdti/PDTICAnterior.vue"),
      },
      {
        path: "resultados-metas",
        name: "app:eqpdti:resultados_metas",
        component: () => import("../views/eqpdti/ResultadosMetas.vue"),
      },

      {
        path: "demandas",
        name: "app:eqpdti:demanda",
        component: () => import("../views/eqpdti/Demanda.vue"),
      },

      {
        path: "documentos-referencia",
        name: "app:eqpdti:documento_referencia",
        component: () => import("../views/eqpdti/DocumentoReferencia.vue"),
      },

      {
        path: "metas",
        name: "app:eqpdti:meta",
        component: () => import("../views/eqpdti/Meta.vue"),
      },

      {
        path: "acoes",
        name: "app:eqpdti:acao",
        component: () => import("../views/eqpdti/Acao.vue"),
      },

      {
        path: "sections",
        name: "app:eqpdti:secao",
        component: () => import("../views/eqpdti/Secao.vue"),
      },
      {
        path: "planejamento-estrategico",
        name: "app:eqpdti:planejamento_estrategico",
        component: () => import("../views/eqpdti/PlanejamentoEstrategico.vue"),
      },
      {
        path: "objetivos-estrategicos",
        name: "app:eqpdti:objetivo_estrategico",
        component: () => import("../views/eqpdti/ObjetivoEstrategico.vue"),
      },
      {
        path: "swot",
        name: "app:eqpdti:swot",
        component: () => import("../views/eqpdti/Swot.vue"),
      },
      {
        path: "principios-diretrizes",
        name: "app:eqpdti:principio_diretriz",
        component: () => import("../views/eqpdti/PrincipioDiretriz.vue"),
      },
      {
        path: "relatorios",
        name: "app:eqpdti:relatorios",
        component: () => import("../views/eqpdti/Relatorios.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!(token && token.length > 0)) {
      next("/login");
    }
  }
  next();
});

export default router;
