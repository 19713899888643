import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { events } from "./events";
import { store } from "./store";
import "noty/src/noty.scss";
import "noty/src/themes/sunset.scss";
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import "./validation";
Vue.config.productionTip = false;
localize("pt_BR", pt_br);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.use(events);
Vue.use(store);
new Vue({
  router,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
